export default class EmployeesFoldersMediasFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.mediaTypeToken = "";
    // this.folderToken = "";
    // this.employeeToken = "";
    this.employeeFolderToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.mediaTypeToken = data.mediaTypeToken;
    // this.folderToken = data.folderToken;
    // this.employeeToken = data.employeeToken;
    this.employeeFolderToken = data.employeeFolderToken;
    this.textSearch = data.textSearch;
  }
}
