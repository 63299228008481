import PaginationData from "@/models/general/PaginationData";
import EmployeesFoldersMedia from "./EmployeesFoldersMedia";
import EmployeesFoldersMediasFilter from "./EmployeesFoldersMediasFilter";

export default class EmployeesFoldersMedias {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.generalStaticList = [];
    this.pagination = new PaginationData();
    this.employeesFoldersMediasData = [];
    this.employeesFoldersMedia = new EmployeesFoldersMedia();
    this.filterData = new EmployeesFoldersMediasFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.generalStaticList = data.generalStaticList;
    this.pagination.fillData(data.employeesFoldersMediaPagination);
    this.employeesFoldersMediasData =
      data.employeesFoldersMediaPagination.employeesFoldersMediaData;
  }
}
