import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";
import EmployeesFoldersMediaImg from "@/assets/images/media.svg";
import EmployeesFoldersMediasFilter from "./EmployeesFoldersMediasFilter";

export default class EmployeesFoldersMedia {
  defaultImg = EmployeesFoldersMediaImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.media = "";
    this.folderMediaToken = "";
    this.employeeFolderToken = "";
    this.employeeFolderNameCurrent = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.mediaTypeToken = "";
    this.mediaTypeNameCurrent = "";
    this.mediaTypeNameEn = "";
    this.mediaTypeNameAr = "";
    this.mediaTypeNameUnd = "";
    this.folderMediaCode = "";
    this.folderMediaPath = "";
    this.folderMediaDate = "";
    this.folderMediaTime = "";
    this.folderMediaDateTime = "";
    this.folderMediaNameCurrent = "";
    this.folderMediaNameEn = "";
    this.folderMediaNameAr = "";
    this.folderMediaNameUnd = "";
    this.folderMediaDescriptionCurrent = "";
    this.folderMediaDescriptionEn = "";
    this.folderMediaDescriptionAr = "";
    this.folderMediaDescriptionUnd = "";
    this.folderMediaNotes = "";
    this.folderMediaArchiveStatus = "";
  }
  fillData(data) {
    this.folderMediaToken = data.folderMediaToken;
    this.employeeFolderToken = data.employeeFolderToken;
    this.employeeFolderNameCurrent = data.employeeFolderNameCurrent;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.mediaTypeToken = data.mediaTypeToken;
    this.mediaTypeNameCurrent = data.mediaTypeNameCurrent;
    this.mediaTypeNameEn = data.mediaTypeNameEn;
    this.mediaTypeNameAr = data.mediaTypeNameAr;
    this.mediaTypeNameUnd = data.mediaTypeNameUnd;
    this.folderMediaCode = data.folderMediaCode;
    this.folderMediaPath = data.folderMediaPath;
    this.folderMediaDate = data.folderMediaDate;
    this.folderMediaTime = data.folderMediaTime;
    this.folderMediaDateTime = data.folderMediaDateTime;
    this.folderMediaNameCurrent = data.folderMediaNameCurrent;
    this.folderMediaNameEn = data.folderMediaNameEn;
    this.folderMediaNameAr = data.folderMediaNameAr;
    this.folderMediaNameUnd = data.folderMediaNameUnd;
    this.folderMediaDescriptionCurrent = data.folderMediaDescriptionCurrent;
    this.folderMediaDescriptionEn = data.folderMediaDescriptionEn;
    this.folderMediaDescriptionAr = data.folderMediaDescriptionAr;
    this.folderMediaDescriptionUnd = data.folderMediaDescriptionUnd;
    this.folderMediaNotes = data.folderMediaNotes;
    this.folderMediaArchiveStatus = data.folderMediaArchiveStatus;
  }

  async getAllEmployeesFoldersMedias(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new EmployeesFoldersMediasFilter()
  ) {
    try {
      let response = await axios.get(
        `/EmployeesFoldersMedia/GetAllEmployeesFoldersMedia?language=${language}&userAuthorizeToken=${userAuthorizeToken}&folderMediaArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&employeeFolderToken=${filterData.employeeFolderToken}&mediaTypeToken=${filterData.mediaTypeToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getStatisticsEmployeeFoldersMedias(
    language,
    userAuthorizeToken,
    filterData = new EmployeesFoldersMediasFilter()
  ) {
    try {
      let response = await axios.get(
        `/EmployeesFoldersMedia/GetStatisticsEmployeesFoldersMedia?language=${language}&userAuthorizeToken=${userAuthorizeToken}&reservationMediaArchiveStatus=false&filterStatus=true&mediaTypeToken=${filterData.mediaTypeToken}&employeeFolderToken=${filterData.employeeFolderToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getEmployeesFoldersMediaDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EmployeesFoldersMedia/GetEmployeesFoldersMediaDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&folderMediaToken=${this.folderMediaToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateEmployeesFoldersMedia(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      folderMediaToken: this.folderMediaToken,
      employeeFolderToken: this.employeeFolderToken,
      mediaTypeToken: this.mediaTypeToken,
      employeesFoldersMediaPath: this.media,
      folderMediaNameEn: this.folderMediaNameEn,
      folderMediaNameAr: this.folderMediaNameAr,
      folderMediaNameUnd: this.folderMediaNameUnd,
      folderMediaCode: this.folderMediaCode,
      folderMediaDescriptionEn: this.folderMediaDescriptionEn,
      folderMediaDescriptionAr: this.folderMediaDescriptionAr,
      folderMediaDescriptionUnd: this.folderMediaDescriptionUnd,
      folderMediaNotes: this.folderMediaNotes,
    };

    if (this.media != "" && this.media != undefined) {
      var formData = new FormData();
      formData.append("employeesFoldersMediaPath", this.media);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.folderMediaToken == "" || this.folderMediaToken == undefined) {
        if (this.media != "" && this.media != undefined) {
          response = await axios.post(
            `/EmployeesFoldersMedia/AddEmployeesFoldersMedia`,
            formData
          );
        }
      } else {
        if (this.media != "" && this.media != undefined) {
          response = await axios.post(
            `/EmployeesFoldersMedia/UpdateEmployeesFoldersMediaWithMedia`,
            formData
          );
        } else {
          response = await axios.post(
            `/EmployeesFoldersMedia/UpdateEmployeesFoldersMediaWithOutMedia`,
            data
          );
        }
      }

      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveEmployeesFoldersMedia(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      folderMediaToken: this.folderMediaToken,
    };

    try {
      return await axios.post(
        `/EmployeesFoldersMedia/ArchiveEmployeesFoldersMedia`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
