import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../general/PaginationData";
import EmployeeFolderImg from "@/assets/images/employeeFolders.svg";
import EmployeeFoldersFilter from "./EmployeeFoldersFilter";

export default class EmployeeFolder {
  defaultImg = EmployeeFolderImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.imagePath = "";
    this.employeeFolderToken = "";
    this.employeeToken = "";
    this.employeeFolderCode = "";
    this.employeeNameCurrent = "";
    this.employeeFolderNameCurrent = "";
    this.employeeFolderNameEn = "";
    this.employeeFolderNameAr = "";
    this.employeeFolderNameUnd = "";
    this.employeeFolderImagePath = "";
    this.employeeFolderDescriptionCurrent = "";
    this.employeeFolderDescriptionEn = "";
    this.employeeFolderDescriptionAr = "";
    this.employeeFolderDescriptionUnd = "";
    this.employeeFolderNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.employeeFolderArchiveStatus = "";
  }
  fillData(data) {
    this.employeeFolderToken = data.employeeFolderToken;
    this.employeeToken = data.employeeToken;
    this.employeeFolderCode = data.employeeFolderCode;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeFolderNameCurrent = data.employeeFolderNameCurrent;
    this.employeeFolderNameEn = data.employeeFolderNameEn;
    this.employeeFolderNameAr = data.employeeFolderNameAr;
    this.employeeFolderNameUnd = data.employeeFolderNameUnd;
    this.employeeFolderImagePath = data.employeeFolderImagePath;
    this.employeeFolderDescriptionCurrent =
      data.employeeFolderDescriptionCurrent;
    this.employeeFolderDescriptionEn = data.employeeFolderDescriptionEn;
    this.employeeFolderDescriptionAr = data.employeeFolderDescriptionAr;
    this.employeeFolderDescriptionUnd = data.employeeFolderDescriptionUnd;
    this.employeeFolderNotes = data.employeeFolderNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.employeeFolderArchiveStatus = data.employeeFolderArchiveStatus;
  }

  async getAllEmployeeFolders(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new EmployeeFoldersFilter()
  ) {
    try {
      let response = await axios.get(
        `/EmployeeFolders/GetAllEmployeeFolders/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeFolderArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfEmployeeFolders(
    language,
    userAuthorizeToken,
    filterData = new EmployeeFoldersFilter()
  ) {
    return await axios.get(
      `/EmployeeFolders/GetDialogOfEmployeeFolders?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeFolderArchiveStatus=false&filterStatus=true&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getEmployeeFolderDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EmployeeFolders/GetEmployeeFolderDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeFolderToken=${this.employeeFolderToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateEmployeeFolder(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      imagePath: this.imagePath,
      employeeFolderToken: this.employeeFolderToken,
      employeeToken: this.employeeToken,
      employeeFolderNameEn: this.employeeFolderNameEn,
      employeeFolderNameAr: this.employeeFolderNameAr,
      employeeFolderNameUnd: this.employeeFolderNameUnd,
      employeeFolderImagePath: this.employeeFolderImagePath,
      employeeFolderDescriptionEn: this.employeeFolderDescriptionEn,
      employeeFolderDescriptionAr: this.employeeFolderDescriptionAr,
      employeeFolderDescriptionUnd: this.employeeFolderDescriptionUnd,
      employeeFolderNotes: this.employeeFolderNotes,
    };

    if (this.imagePath != "" && this.imagePath != undefined) {
      var formData = new FormData();
      formData.append("imagePath", this.imagePath);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (
        this.employeeFolderToken == "" ||
        this.employeeFolderToken == undefined
      ) {
        if (this.imagePath != "" && this.imagePath != undefined) {
          response = await axios.post(
            `/EmployeeFolders/AddEmployeeFolderWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/EmployeeFolders/AddEmployeeFolderWithoutImage`,
            data
          );
        }
      } else {
        if (this.imagePath != "" && this.imagePath != undefined) {
          response = await axios.post(
            `/EmployeeFolders/UpdateEmployeeFolderWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/EmployeeFolders/UpdateEmployeeFolderWithoutImage`,
            data
          );
        }
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveEmployeeFolder(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeFolderToken: this.employeeFolderToken,
    };

    try {
      return await axios.post(`/EmployeeFolders/ArchiveEmployeeFolder`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
